/** @jsxRuntime classic */
/** @jsx jsx */
import { CloseOutlined } from "@ant-design/icons";
import { Redirect, SharedLayout } from "@app/components";
import { useSharedQuery } from "@app/graphql";
import { jsx } from "@emotion/react";
import { Button, Carousel, Col, Row } from "antd";
import { NextPage } from "next";
import { useState } from "react";
import ReactPlayer from "react-player";

const Home: NextPage = () => {
  const query = useSharedQuery();
  const [displayVideo, setDisplayVideo] = useState({ display: "none", width: "100%", height: "100%" });
  const [displayX, setDisplayX] = useState({ display: "none" });
  const [displayVideoClick, setDisplayVideoClick] = useState({ display: "block" });
  const [playing, setPlaying] = useState(false);

  const startVideo = () => {
    setDisplayVideo({ display: "block", width: "100%", height: "100%" });
    setDisplayX({ display: "block" });
    setDisplayVideoClick({ display: "none" });
    setPlaying(true);
  };

  const endVideo = () => {
    setDisplayVideo({ display: "none", width: "100%", height: "100%" });
    setDisplayX({ display: "none" });
    setDisplayVideoClick({ display: "block" });
    setPlaying(false);
    scrollDown();
  };

  const scrollDown = () => {
    const scrollTo = document.getElementById("reviews");

    // eslint-disable-next-line no-unused-expressions
    scrollTo?.scrollIntoView({ behavior: "smooth", block: "end" });
  };

  if (query.data?.currentUser) {
    return <Redirect href="/dashboard"></Redirect>;
  }

  return (
    <SharedLayout title="" query={query}>
      {/* VIDEO */}
      {/* <a id="scrollTo" style={{ position: "absolute", top: "110vh", marginBottom: "66px" }}></a> */}

      <Col xs={24} sm={24} md={12} lg={10} xl={10} className="orangeLogo">
        <Row align="middle" justify="end" className="orangeLogoRow">
          <img className="logoImage" alt="Rhymewit Logo" src="/YellowLogo.svg" data-cy="rhymewit-logo-img" />
          <p className="mottoText1 hideMobile">Lyrical Puzzles to Engage Your Classroom</p>
          <p className="mottoText2 hideMobile">Fast, Fun, & Standards-aligned</p>
          <p className="mottoText1 showMobile">Lyrical Puzzles to Engage Your Classroom</p>
          <p className="mottoText2 showMobile">Fast, Fun, & Standards-aligned</p>
        </Row>
      </Col>
      <Col xs={24} sm={24} md={12} lg={14} xl={14}>
        <Row align="middle" justify="center" className="videoClick laptopMockup">
          <img src="/playCircle.svg" alt="Play Video" onClick={startVideo} style={displayVideoClick} className="play" />
        </Row>
        <CloseOutlined className="x" style={displayX} onClick={endVideo} />
        <ReactPlayer
          id="video"
          url="/rhymewitHomepageVidV1.mp4"
          width="100%"
          height="100%"
          playing={playing}
          controls={true}
          style={displayVideo}
          onEnded={endVideo}
        />
      </Col>
      <Col span={24}>
        <Row align="middle" justify="center" className="expandMore">
          {/* <p className="mottoText2 showMobile">Fast. Engaging. Fun. Flexible.</p> */}

          <img src="/expand_more.svg" alt="Expand More" onClick={scrollDown} />
        </Row>
      </Col>

      {/* FREE TRIAL */}
      <Col span={24} id="freeTrial">
        <p className="trialText">Try out Rhymewit free for 6 months! No credit card needed.</p>
        <Button className="trialButton buttonLargeScreen">
          <a href="/register">Try Rhymewit</a>
        </Button>
        <Button className="trialButton buttonMobile">
          <a href="/register">Try Rhymewit for free</a>
        </Button>
      </Col>

      {/* REVIEWS */}
      <Col span={24} id="reviews">
        <p className="reviewsTitle">What people have to say</p>
      </Col>

      <Col span={24} className="reviewsSection">
        <Carousel effect="fade" className="carousel">
          {/* review1 */}
          <div className="reviewsBox">
            <p className="reviewsQuotes">,,</p>
            <div className="centered">
              <p className="reviewText">
                Rhymewit was a hit with my middle school science students! We had so much fun rhyming together as a
                whole class. The rhyme pairs became mnemonic devices to help students remember the unit's key
                vocabulary.
              </p>
              <p className="reviewName">- Cristina, 5th Grade Science Teacher</p>
            </div>
          </div>

          {/* review2 */}
          <div className="reviewsBox">
            <p className="reviewsQuotes">,,</p>
            <div className="centered">
              <p className="reviewText">
                This is by far the most engaged my students have been on zoom all year. They were into it. They want
                more!
              </p>
              <p className="reviewName">- Joel, 7th Grade Social Studies Teacher</p>
            </div>
          </div>

          {/* review3 */}
          <div className="reviewsBox">
            <p className="reviewsQuotes">,,</p>
            <div className="centered">
              <p className="reviewText">
                They’re great for introducing vocabulary as well as reinforcement at anytime during a unit. They’re also
                great to use for the whole class as well as an independent assignment.
              </p>
              <p className="reviewName">- Tori, 9th Grade Science Teacher</p>
            </div>
          </div>

          {/* ADDITIONAL REVIEWS TEMPLATE */}
          {/* <div className="reviewsBox">
            <p className="reviewsQuotes">,,</p>
            <div className="centered">
            <p className="reviewText">
              ADD TEXT HERE
            </p>
            <p className="reviewName">- ADD NAME HERE</p>
            </div>
          </div> */}
        </Carousel>
      </Col>

      {/* TOM INFO */}
      <Col span={24} id="tomInfo">
        <p className="tomInfoTitle">Developed by an 8th Grade Science Teacher</p>
      </Col>

      <Col xs={24} sm={24} md={12} lg={12} xl={12} id="tomLeft">
        <img className="tomInfoImage" src="/DallasSelfie.jpg" />
        <p className="tomInfoCaption">
          Tom visits Ms. Bershell’s science class in Dallas, TX for their “Hidden Figures” music video collaboration
        </p>
      </Col>
      <Col xs={24} sm={24} md={12} lg={12} xl={12} id="tomRight">
        <p className="tomInfoText">
          <a href="https://www.sciencewithtom.com/about">Tom McFadden</a> (8th grade science teacher & founder of{" "}
          <em>
            <a href="https://www.sciencewithtom.com">Science With Tom</a>
          </em>
          ) has been combining music, video, and education for over a decade. Rhymewit is the product of years of
          iteration, with the goal of bringing creative, student-centered learning to every classroom.
        </p>
        <Button className="tomInfoButton">
          <a href="https://www.sciencewithtom.com/about">About Tom</a>
        </Button>
      </Col>

      {/* FREE TRIAL */}
      <Col span={24} id="freeTrial2">
        <p className="trialText2">Try out Rhymewit free for 6 months! No credit card needed.</p>
        <p className="trialText2Short">Try out Rhymewit free for 6 months! No credit card needed.</p>
        <Button className="trialButton">
          <a href="/register">Try Rhymewit for free</a>
        </Button>
        <p className="trialText2Short">
          Sign up now to access our entire library and share with your class. No student accounts needed!
        </p>

        <div className="blobGroup">
          <img className="blueBlob" src="/BlueBlob.svg" />
        </div>
      </Col>
    </SharedLayout>
  );
};

export default Home;
